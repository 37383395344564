




































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import _mapValues from 'lodash/mapValues'
import draggable from 'vuedraggable'
import { Environment, EnvVariable } from '@/models'
import profileFields from '@/components/form/Fields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import cleanData from '@/utils/gql/cleanData'
import { EnvironmentVariableUpdateFragment } from './fragments'

@Component({
  components: {
    Loading,
    profileFields,
    draggable
  },
  apollo: {
    savedEnvironment: {
      query: gql`
        query getEnvironment($environmentId: ID) {
          savedEnvironment: environment(environmentId: $environmentId) {
            _id
            ...EnvironmentVariableUpdateFragment
          }
        }
        ${EnvironmentVariableUpdateFragment}
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class EnvironmentEditENV extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  savedEnvironment: Partial<Environment> | null = null
  environment: Partial<Environment> | null = null
  saving = false
  optionsSheetOpen: Record<string, any> = {}

  fieldErrors: Record<string, any> = {}


  @Watch('savedEnvironment')
  updateEnvironment() {
    this.environment = _cloneDeep(this.savedEnvironment)
    if (!this.environment) return
    if (!this.environment.customVariables) this.environment.customVariables = []
  }

  get fieldNameValidationRules() {
    return [
      (fName: string) => !!fName.trim() || 'Requerido.',
      (fName: string) => !!fName.match(/^[A-z][A-z\d]*$/) || 'Inválido',
      (fName: string) =>
        (
          this.environment?.customVariables?.filter((f) => f.name === fName) ||
          []
        ).length <= 1 || 'Debe ser único.'
    ]
  }
  get valid() {
    if (!this.environment?.customVariables) return false
    return !this.environment.customVariables.some(
      (f) => this.fieldErrors[f.name]
    )
  }

  get dirty() {
    return !_isEqual(this.environment, this.savedEnvironment)
  }

  validateField(field: EnvVariable) {
    const validationResult = this.fieldNameValidationRules.find(
      (validate) => validate(field.name) !== true
    )
    this.fieldErrors[field.name] = !!validationResult
  }

  addField() {
    if (!this.environment) return
    if (!this.environment.customVariables) this.environment.customVariables = []
    this.environment.customVariables.push({
      name: '',
      value: ''
    })
  }

  removeField(field: EnvVariable) {
    if (!this.environment?.customVariables) return
    this.environment.customVariables = this.environment.customVariables.filter(
      (f) => f.name !== field.name
    )
  }

  openOptionsForField(field: EnvVariable) {
    this.$set(this, 'optionsSheetOpen', { [field.name]: true })
  }

  normalize() {
    if (!this.environment) return
    if (!this.environment.customVariables) this.environment.customVariables = []
    this.environment.customVariables = this.environment.customVariables.filter(
      (f) => f && f.name.trim()
    )
    this.environment?.customVariables?.forEach(this.validateField.bind(this))
  }

  async save() {
    if (!this.environment || this.saving) return
    this.normalize()
    if (!this.valid) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($environmentId: ID, $customVariables: [EnvVariableInput]) {
            setEnvironmentVariables(
              environmentId: $environmentId
              customVariables: $customVariables
            ) {
              _id
              ...EnvironmentVariableUpdateFragment
            }
          }
          ${EnvironmentVariableUpdateFragment}
        `,
        // Parameters
        variables: {
          environmentId: this.environment._id,
          customVariables: cleanData(
            this.environment,
            EnvironmentVariableUpdateFragment
          ).customVariables
        }
      })

      this.savedEnvironment = result.data.setEnvironmentVariables
      this.$emit('save', result.data.updateFilter)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
      console.log(
        cleanData(this.environment, EnvironmentVariableUpdateFragment)
      )
    } finally {
      this.saving = false
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
