var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"background__logo mb-10",staticStyle:{"height":"100%"}},[_c('v-container',[_c('v-row',{staticClass:"my-2 mx-5",attrs:{"id":"description-row"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 v-flex align-center"},[_c('v-col',{staticClass:"pa-0 mr-3",attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"title-icon"},[_vm._v("api")])],1),_c('v-col',{staticClass:"pa-0"},[_c('h1',{staticClass:"my-0"},[_vm._v("Variables De Entorno")])])],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"pb-0"},[_c('p',{staticClass:"ma-0",attrs:{"id":"text-description"}},[_vm._v(" Aquí puedes agregar variables de entorno estas estarán siempre disponibles en Bloques de Contenido, Bloques Vue, Tareas y APIs dentro de una variable \"env\". ")])])],1)],1)],1),(!_vm.savedEnvironment && _vm.$apollo.loading)?_c('Loading'):(_vm.environment)?_c('v-card',{staticClass:"d-flex mx-8 overflow-hidden",attrs:{"id":"profiles-setting-card","elevation":"5","height":"calc(100vh - 120px)"}},[_c('v-row',{staticStyle:{"position":"relative"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"py-2 px-3",attrs:{"id":"collection-list__header--title"}},[_c('span',{attrs:{"id":"header__title"}},[_vm._v("Campos de perfil")])]),_c('div',{staticStyle:{"height":"calc(100% - 44px)"},attrs:{"id":"collection-detail__body"}},[_c('v-card-text',{staticStyle:{"height":"calc(100% - 52px)","overflow-y":"scroll"}},[_c('v-slide-y-transition',[(_vm.savedEnvironment &&
                    _vm.savedEnvironment.customVariables &&
                    _vm.savedEnvironment.customVariables.length > 0 &&
                    _vm.dirty
                    )?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" Modificar o eliminar campos existentes puede llevar a inconsistencias de datos. Por favor proceder con precaución. ")]):_vm._e()],1),_c('v-simple-table',{staticClass:"mb-2 px-1"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"48px"}}),_c('th',{staticClass:"text-left"},[_vm._v("Nombre")]),_c('th',{staticClass:"text-left"},[_vm._v("Valor")]),_c('th',{staticStyle:{"width":"48px"}})])]),_c('draggable',{attrs:{"tag":"tbody","handle":".field-handle","animation":200,"scroll-sensitivity":128,"force-fallback":true},model:{value:(_vm.environment.customVariables),callback:function ($$v) {_vm.$set(_vm.environment, "customVariables", $$v)},expression:"environment.customVariables"}},_vm._l((_vm.environment.customVariables),function(field,index){return _c('tr',{key:field._id},[_c('td',{staticClass:"field-handle",staticStyle:{"width":"48px"}},[_c('v-icon',[_vm._v("drag_handle")])],1),_c('td',{class:{
                        red: _vm.fieldErrors[field.name],
                        'lighten-3': _vm.fieldErrors[field.name]
                      }},[_c('v-text-field',{attrs:{"solo":"","dense":"","flat":"","hide-details":"","background-color":"transparent","error":_vm.fieldErrors[index],"label":"Nombre"},on:{"input":function($event){return _vm.validateField(field, index)}},model:{value:(field.name),callback:function ($$v) {_vm.$set(field, "name", $$v)},expression:"field.name"}})],1),_c('td',[_c('v-text-field',{attrs:{"solo":"","dense":"","flat":"","hide-details":"","label":"valor","background-color":"transparent"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeField(field)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)])}),0)],1),_c('v-btn',{attrs:{"block":"","dark":"","color":"#59a4f4"},on:{"click":_vm.addField}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Añadir Campo ")],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.updateEnvironment}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"#59a4f4","dark":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty || !_vm.valid,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }